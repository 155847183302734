body{
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    width: 100%;
}
#brands, #contact{
        min-height:80vh;
        display: -ms-flexbox;
        display: -webkit-flex;
             /* Center vertically */
             align-items: center;
             /*Center horizontaly */
            
      
}
p {
    font-family: 'Roboto', sans-serif;
}
.text {
    font-family: 'Roboto', sans-serif;
}
.animate:hover {
        -moz-transform: rotate(1deg);
        -webkit-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        -ms-transform: rotate(1deg);
        transform: rotate(1deg);
}


input {
    border: none;
    width:52%;
    border-radius: 5px;
    padding:5px;
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 6px;
}
input:focus {
    background-color: #e4e4e4;
    outline-width: 0;
}
button {
    background-color: #091a2d;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #00be00;
    line-height: 1.5;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding-bottom: 6px;
}

/* team */
.closebtn {
    float:right;
    color: red;
    font-size: 35px;
    cursor: pointer;
  } 
  .container {
    position: relative;
  }
  .texture{
    background:#091a2d ;
    color:'white'
  }
  .middle {
      text-align: center;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0%;    
    background:
    linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #091a2d 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #091a2d 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #091a2d 10px, transparent 10px),
    linear-gradient(#091a2d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);   
    background-color:#091a2d;
    background-size:14px 14px;
    width:100%;
  }
  .container:hover  {
    opacity: 1;
  }
  .container:hover .middle {
    opacity: 1;
  }
  .text {    
    text-align: center;
    color: white;
    font-size: 22px;
  }
.teamimga{
    width: 100%;
    height: auto; 
    background-size: contain;
    background-repeat: no-repeat;
    
}   
.mike{
    height: 365px;    
    cursor:pointer;   
    background:url("./assets/Team/mike.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}

.tyrus{
    min-height: 365px;    
    cursor:pointer;   
    background:url("./assets/Team/tyrus.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.tyrus:hover {
    background: url("./assets/Team/tyrush.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.nishma{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/nishma.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.nishma:hover {
    background: url("./assets/Team/nishmah.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.leonard{
    min-height: 365px; 
    cursor:pointer;   
    background:url("./assets/Team/leonard.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.leonard:hover {
    background: url("./assets/Team/leonardh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.matt{
    min-height: 365px; 
    cursor:pointer;   
    background:url("./assets/Team/matt.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.matt:hover {
    background: url("./assets/Team/matth.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.stephen{
    min-height: 365px; 
    cursor:pointer;   
    background:url("./assets/Team/stephen.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.stephen:hover {
    background: url("./assets/Team/stephenh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.joe{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/joe.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.joe:hover {
    background: url("./assets/Team/joeh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.tim{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/tim.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.tim:hover {
    background: url("./assets/Team/timh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.henry{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/henry.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.henry:hover {
    background: url("./assets/Team/henryh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.tori{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/tori.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.tori:hover {
    background: url("./assets/Team/torih.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.adam{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/adam.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.adam:hover {
    background: url("./assets/Team/adamh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.caleb{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/caleb.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.caleb:hover {
    background: url("./assets/Team/calebh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
    
}
.kira{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/kira.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.kira:hover {
    background: url("./assets/Team/kirah.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
.tyler{
    min-height: 365px;     
    cursor:pointer;   
    background:url("./assets/Team/tyler.jpg");
    background-size: contain;
    background-repeat: no-repeat;
}
.tyler:hover {
    background: url("./assets/Team/tylerh.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 600px) {
    /* .teamimg1:hover {
        background: url("./assets/teama.jpg"); 
        background-size: contain;
        background-repeat: no-repeat;  
    } */
    .text {    
        display: none;
      }
      .middle {    
        display: none;
      }
}